import styled from "styled-components"
import media from 'styled-media-query'

export const Container = styled.section`
  background: #09090A;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  transition: opacity 0.4s;

  .ais-InstantSearch__root {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .ais-SearchBox,
  .ais-Stats {
    padding: 0.5rem 3rem;
  }
  .ais-SearchBox {
    padding-top: 4rem;

    ${media.lessThan('large')`
      padding: 1.3rem 1rem 1.3rem 1rem;
    `}
  }

  .ais-Stats {
    color: #ffdb77;
    margin-bottom: 32px;
  }

  .ais-SearchBox-input {
    background: none;
    border: 1px solid #ffdb77;
    border-radius: 5px;
    /* border-bottom: 1px solid #38444d; */
    color: #ffdb77;
    display: flex;
    font-size: 1.6rem;
    padding: 0.5rem;
    width: 100%;
    &::placeholder {
      color: #999;
    }

    &.focus {
      border: 1px solid #ffdb77;
      border-radius: 10px;
      /* border-bottom: 1px solid #38444d; */
      color: #ffdb77;
    }
  }
  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`

export const RedesSociaisUl = styled.ul`
  display: none;

  ${media.lessThan("large")`
    display: flex;
    background-color: #09090A;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
  `}
`

export const RedesSociaisLi = styled.li`
  padding: 0;
  margin-top: 48px;

  & + li {
    margin-left: 32px;
  }
`

export const RedesSociaisLink = styled.a`
  color: #d9d9d9;
  text-decoration: none;
  &:hover {
    color: #ffdb77;
  }
`

export const RedesSociaisIcon = styled.div`
  fill: #d9d9d9;
  width: 48px;
  height: 48px;
`
