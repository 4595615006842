import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const PostItemLink = styled(Link)`
  color: #d9d9d9;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;

  body#grid & {
    background-color: #09090A;
  }

  &:hover {
    color: #eba417;
  }
`

export const Container = styled.section`
  align-items: center;
  border: none;
  background-color: #121214;
  border-radius: 10px;
  display: flex;
  padding: 1rem;
  margin-bottom: 32px;
  width: 100%;

  body#grid & {
    border: none;
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: center;
  }

  ${media.lessThan('large')`
    padding: 1.3rem 3rem 1.3rem 1rem;
  `}
`

export const PostItemTag = styled.div`
  align-items: center;
  background: ${props => props.background ? props.background : '#eba417'};
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 1.3rem;
  font-weight: 700;
  justify-content: center;
  min-height: 90px;
  min-width: 90px;
  text-transform: uppercase;

  body#grid & {
    margin-bottom: 1.5rem;
  }

  ${media.lessThan('large')`
    min-height: 70px;
    min-width: 70px;
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 1rem;
  `}
`

export const PostItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;

  ${media.lessThan('large')`
    margin: 0;
  `}
`

export const PostItemDate = styled.time`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  ${media.lessThan('large')`
    font-size: 0.7rem;
  `}
`

export const PostItemTitle = styled.h1`
  font-size: 1.5rem;
  color: #ffdb77;
  font-weight: 700;
  line-height: 1.1;
  margin: 0.2rem 0 0.5rem;

  body#grid & {
    line-height: 1.1;
    margin: 0.8rem 0;
  }

  ${media.lessThan('large')`
    font-size: 1.3rem;
  `}

  ${media.lessThan('small')`
    font-size: 1rem;
  `}
`

export const PostItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;

  ${media.lessThan('large')`
    font-size: 1rem;
  `}

  ${media.lessThan('small')`
    font-size: 0.8rem;
  `}
`
