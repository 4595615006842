import React from "react"

import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, Hits, Stats } from "react-instantsearch-dom"

import Hit from "./Hit"
import {
  Container,
  RedesSociaisIcon,
  RedesSociaisLi,
  RedesSociaisLink,
  RedesSociaisUl
} from "./styles"
import links from "../SocialLinks/content"
import Icons from "../SocialLinks/Icons"

const Search = ({algolia}) => {
  const searchClient = algoliasearch(algolia.appId, algolia.searchOnlyApiKey)

  return (
    <>
      <Container>
        <InstantSearch
          searchClient={searchClient}
          indexName={algolia.indexName}
        >
          <SearchBox translations={{ placeholder: "Pesquisar..." }} />
          <Stats
            translations={{
              stats(nbHits, timeSpentMs) {
                return `${nbHits} resultados encontrados em ${timeSpentMs}ms`
              }
            }}
          />
          <Hits hitComponent={Hit} />
        </InstantSearch>
      </Container>

      <RedesSociaisUl>
        {links.map((link, i) => {
          const Icon = Icons[link.label]

          return (
            <RedesSociaisLi key={i}>
              <RedesSociaisLink
                href={link.url} title={link.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RedesSociaisIcon>
                  <Icon />
                </RedesSociaisIcon>
              </RedesSociaisLink>
            </RedesSociaisLi>
          )
        })}
      </RedesSociaisUl>
    </>
  )
}

export default Search
